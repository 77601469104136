import request from '../utils/request';
//公告列表
export function toListMessage(data) {
  return request({
    url: '/info/toListMessage.html',
    method: 'post',
    data
  });
}

//资讯详情
export function getInfoDetail(data) {
  return request({
    url: '/info/getInfoDetail.html',
    method: 'post',
    data
  });
}

//学时证明
export function toCreditZM(data) {
  return request({
    url: '/web/toCreditZM.html',
    method: 'post',
    data
  });
}
