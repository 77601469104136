import request from '../utils/request';
//登录
export function login(data) {
  return request({
    url: '/user/login.html',
    method: 'post',
    data
  });
}

//退出登录
export function logout(data) {
  return request({
    url: 'logout',
    method: 'post',
    data
  });
}

//检查手机号（第一步）
export function toCheckPhone(data) {
  return request({
    url: '/web/toCheckPhone.html',
    method: 'post',
    data
  });
}

//发送短息（第二步）
export function sendDx3(data) {
  return request({
    url: '/web/sendDx3.html',
    method: 'post',
    data
  });
}

//检查手机验证码（第二步） 
export function toCheckPhone2(data) {
  return request({
    url: '/web/toCheckPhone2.html',
    method: 'post',
    data
  });
}

//获取用户信息（第三步） 
export function toResetPassword3(data) {
  return request({
    url: '/web/toResetPassword3.html',
    method: 'post',
    data
  });
}

//更新密码（第三步） 
export function toUpdatePass(data) {
  return request({
    url: '/web/toUpdatePass.html',
    method: 'post',
    data
  });
}

//获取图片验证码
export function captchaImage() {
  return request({
    url: '/captchaImage.html',
    method: 'get',
    
  });
}

