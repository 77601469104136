<template>
  <div>
    <div class="head">
      <div style="display: flex; justify-content: center">
        <div>
          <div class="headBanner">
            <div class="logoImg">
              <img style="width: 100%; height: 100%"
                   :src="this.$store.state.m_user.logo"
                   alt="" />
            </div>

            <div class="searChBox">
              <div style="width: 271px">
                <el-input class="searCh"
                          placeholder="搜索感兴趣的专业"
                          v-model="className"
                          clearable
                          prefix-icon="el-icon-search"
                          @clear="searClass(1)"></el-input>
              </div>
              <!-- <input /> -->
              <!-- <img class="searchIcon"
                   src="../../assets/indexImg/search.png"
                   alt=""> -->
              <div class="searBtn"
                   @click="searClass">搜 索</div>
            </div>

            <div class="userxi"
                 v-if="this.$store.state.m_user.realName == '' || this.$store.state.m_user.realName == null">
              <div style="cursor: pointer"
                   class="mlet hov"
                   @click="login">登录</div>
              <div class="mlet">/</div>
              <div style="cursor: pointer"
                   class="mlet hov"
                   @click="regist">注册</div>
            </div>

            <div class="userxi"
                 v-else>
              <div style="cursor: pointer; color: #2878ff"
                   class="mlet hov"
                   @click="goStudy">{{ this.$store.state.m_user.realName }}</div>
              <div class="mlet">|</div>
              <div style="cursor: pointer"
                   class="mlet hov"
                   @click="loginOut">退出</div>
            </div>
          </div>
          <div class="navBox"
               style="position: relative">
            <div class="tab1">
              <div style="display: flex"
                   @click="changeKe">
                <img class="lu"
                     src="../../assets/indexImg/lu.png"
                     alt="" />
                <div class="textLus">课 程 类 目</div>
                <img v-if="iconSHow"
                     class="topIcon"
                     src="../../assets/indexImg/topIcon.png"
                     alt="" />
                <img v-if="!iconSHow"
                     class="topIcon"
                     src="../../assets/indexImg/arrow_down.png"
                     alt="" />
              </div>

              <div class="tab1List"
                   v-if="iconSHow"
                   style="">
                <div style="position: relative; z-index: 10000">

                  <div class="list1"
                       v-for="item in this.$store.state.m_user.list"
                       :key="item.id"
                       style="margin-top: 20px;">
                    {{ item.codeName }}

                    <div class="slist">
                      <div class="slistbox"
                           v-for="msg in item.codeList"
                           :key="msg.id"
                           @click="goList(msg, item)">
                        {{ msg.codeName }}
                      </div>
                      <div class="slistbox"
                           style="color: #1f73bc; text-decoration: underline"
                           @click="moke">更多</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div :class="path == '/' ? 'tab active' : 'tab'"
                 @click="navGet('1')">网站首页</div>
            <div :class="path == '/lsitFs' ? 'tab active' : 'tab'"
                 @click="navGet('2')">报名选课</div>
            <div :class="path == '/car' ? 'tab active' : 'tab'"
                 @click="navGet(3)">购物车</div>
            <div :class="path == '/studyMain'? 'tab active' : 'tab'"
                 @click="navGet(7)">学习中心</div>
            <div :class="path == '/wzgn' ? 'tab active' : 'tab'"
                 @click="navGet(4)">帮助中心</div>
            <div :class="path == '/tzgg' ? 'tab active' : 'tab'"
                 @click="navGet(5)">新闻资讯</div>
            <div :class="path == '/zscy' ? 'tab active' : 'tab'"
                 @click="navGet(6)">证书查验</div>
            <div :class="path == '/studyMain1'  ? 'tab active' : 'tab'"
                 @click="navGet(8)">申请发票</div>

            <div style="
                position: absolute;
                top: 0px;
                right: -200px;
                font-size: 16px;
                font-weight: bold;
                color: #ed0f08;
                width: 220px;
                height: 40px;
                text-align: center;
                line-height: 40px;
                background: rgba(237, 15, 8, 0.1);
                border-radius: 6px;
              ">
              客服联系: 400-8816-822
            </div>
          </div>
        </div>
      </div>
      <div class="navBorder"></div>
    </div>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import { logout } from '@/api/login.js';
import { mapMutations } from 'vuex';
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  props: ['value'],
  data() {
    // 这里存放数据
    return {
      iconSHow: false,
      indexShow: 1,
      logo: '',
      url: '',
      list: [],
      path: '',
      className: '',
      userName: '', //用户名称
      localValue: '0702',
      fp:'',
    };
  },
  // 监听属性 类似于data概念
  computed: {
    // localValue: {
    //   get() {
    //     return this.value; // 返回父组件传递的值
    //   }
    // }
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    ...mapMutations('m_user', ['REAL_NAME']),
    //点击姓名跳到学习中心
    goStudy() {
      this.$router.push({
        path: '/studyMain'
      });
    },
    //更多
    moke() {
      this.$router.push({
        path: '/lsitFs'
      });
    },
    //目录点击
    goList(msg, item) {
      console.log(msg, item);
      this.$router.push({
        path: '/lsitFs',
        query: {
          codeNo: msg.codeNo, //第二级
          fCodeNo: item.codeNo //第一级
        }
      });
    },
    //退出登录
    loginOut() {
      this.REAL_NAME('');
      localStorage.removeItem('token');
      this.userName = '';
      logout({}).then(res => {
        this.$message({
          message: '退出登录成功！',
          type: 'success'
        });
        this.$router.push('/');
      });
    },
    searClass(id) {
      if (this.className == '' && id !== 1) {
        return false;
      }
      this.localValue = {
        localValue: '',
        className: this.className
      };
      console.log(this.$route.path);
      this.$emit('updateParentValue', this.localValue); // 当输入框的值发生变化时，通过$emit事件告知父组件更新值
      if (this.$route.path !== '/lsitFs') {
        this.$router
          .push({
            path: '/lsitFs',
            query: {
              className: this.className
            }
          })
          .catch(err => {
            console.log('重复路由');
          });
      }
    },
    changeKe() {
      this.iconSHow = !this.iconSHow;
      console.log(this.iconSHow);
    },
    login() {
      this.$router.push('/login');
    },
    regist() {
      this.$router.push('/regist');
    },
    navGet(id) {
      this.indexShow = id;
      console.log(this.indexShow);

      if (id == 1) {
        this.$router.push('/').catch(err => {
          console.log('重复路由');
        });
      } else if (id == 2) {
        this.$router.push('/lsitFs').catch(err => {
          console.log('重复路由');
        });
      } else if (id == 3) {
        this.$router.push('/car').catch(err => {
          console.log('重复路由');
        });
      } else if (id == 4) {
        this.$router.push('/wzgn').catch(err => {
          console.log('重复路由');
        });
      } else if (id == 5) {
        this.$router.push('/tzgg').catch(err => {
          console.log('重复路由');
        });
      } else if (id == 6) {
        this.$router.push('/zscy').catch(err => {
          console.log('重复路由');
        });
      } else if (id == 7) {
        this.$router.push('/studyMain').catch(err => {
          console.log('重复路由');
        });
      } else if (id == 8) {
        this.$router
          .push({
            path: '/studyMain1',
            query: {
              id: '8'
            }
          })
          .catch(err => {
            console.log('重复路由');
          });
      }
    },
    destroyed() {
      localStorage.removeItem('userName1');
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    if (this.$route.query.className) {
      this.className = this.$route.query.className;
    }
    this.path = this.$route.path;
    console.log(this.path);
    if (this.path == '/') {
      this.iconSHow = true;
    } else {
      this.iconSHow = false;
    }
    this.userName = localStorage.getItem('userName1');
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    setTimeout(() => {
      this.url = this.$store.state.m_user.url;
      this.list = this.$store.state.m_user.list;
    }, 300);
  },
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {} // 生命周期 - 挂载之前
};
</script>
<style lang="less" scoped>
/* //@import url(); 引入公共css类 */
.head {
  font-family: Regular;
  width: 100%;
  background: #fff;
  padding-top: 20px;

  .headBanner {
    width: 1200px;
    display: flex;
    height: 80px;
    justify-content: space-between;

    .logoImg {
      width: 220px;
      height: 60px;
    }
    .searChBox {
      width: 350px;
      height: 40px;
      margin-top: 12px;
      position: relative;
      .el-input__inner {
        width: 350px !important;
        height: 40px !important;
        background: #ffffff !important;
        border: 1px solid #dddddd;
        border-radius: 20px !important;
        padding-left: 46px !important;
        padding-right: 80px !important;
        position: relative !important;
        color: #dddddd !important;
      }
      .searCh {
      }
      .searchIcon {
        position: absolute;
        top: 10px;
        left: 20px;
        width: 16px;
        height: 16px;
      }
      .searBtn {
        position: absolute;
        right: 0;
        top: 0;
        width: 80px;
        height: 40px;
        background: #2878ff;
        border: 1px solid #2878ff;
        opacity: 1;
        font-size: 16px;
        color: #fff;
        line-height: 36px;
        text-align: center;
        cursor: pointer;
        border-radius: 0px 20px 20px 0px;
      }
    }
    .userxi {
      margin-top: 25px;
      font-size: 14px;
      color: #333;
      display: flex;
      height: 20px;
      .mlet {
        margin-left: 5px;
      }
      .hov:hover {
        opacity: 0.7;
      }
    }
  }
  .navBox {
    width: 1200px;
    height: 48px;
    display: flex;
    .tab1 {
      width: 220px;
      height: 48px;
      background: #2878ff;
      opacity: 1;
      border-radius: 0px;
      padding-left: 10px;
      cursor: pointer;
      display: flex;
      font-size: 18px;
      color: #fff;
      display: flex;
      align-items: center;
      position: relative;
      .tab1List {
        position: absolute;
        bottom: -403px;
        width: 220px;
        height: 403px;
        background: #fff;
        left: 0;
        z-index: 9999;
        box-shadow: 0px 0px 4px #333333;
        font-size: 16px;
        color: #000;
        padding: 12px 10px;
        .list1 {
          margin-bottom: 30px;
          cursor: pointer;
          .slist {
            display: flex;
            margin-top: 10px;
            flex-wrap: wrap;
            .slistbox {
              cursor: pointer;
              font-size: 14px;
              color: #999;
              opacity: 0.7;
              margin-right: 10px;
              margin-bottom: 10px;
            }
            .slistbox:hover {
              opacity: 1;
            }
          }
        }
      }
      .lu {
        width: 20px;
        height: 16px;
        margin-top: 5px;
      }
      .textLus {
        margin-left: 10px;
      }
      .topIcon {
        width: 15px;
        height: 8px;
        margin-top: 9px;
        margin-left: 54px;
      }
    }

    .tab {
      width: 120px;
      text-align: center;
      line-height: 48px;
      font-size: 16px;
      cursor: pointer;
    }
    .active {
      color: #2878ff;
      font-weight: bold;
    }
  }
  .navBorder {
    width: 100%;
    border-bottom: 2px solid #2878ff;
  }
}
</style>
