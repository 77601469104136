<template>
  <div>
    <div class="bottom">
      <div class="bottomContent">
        <div>
          <div class="neir">
            <div @click="goDetil(item)" class="fo" style="display: flex;" v-for="item in this.$store.state.m_user.aboutList" :key="item.ID">
              <div>
                {{ item.TITLE }}
              </div>
              <span style="margin: 0 8px;">|</span>
            </div>

          </div>

          <!-- <div class="nd">
            联系方式:客服电话400-8816-822（ 周一至周五8:30-12:00/14:00-17:30）
          </div>
          <div class="nd">
            客服QQ: 3550339480
          </div>
          <div class="nd">
            客服QQ群: 458367595
          </div>
          <div class="nd">
            客服邮箱: zhangjuan@gc80.cn
          </div> -->
          <div v-if="this.$store.state.m_user.bottomValue">
            <div v-html="this.$store.state.m_user.bottomValue.PIC_DESC">

            </div>
          </div>
        </div>

        <div>
          <div class="tu" v-if="this.$store.state.m_user.gzhEwm">
            <img :src="this.$store.state.m_user.gzhEwm.PIC_URL"
                 :alt="this.$store.state.m_user.gzhEwm.PIC_TITLE">
            <div style="text-align: center;">
              {{ this.$store.state.m_user.gzhEwm.PIC_TITLE }}
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="tio"
         v-if="this.$store.state.m_user.beiAn">
      <a href="https://beian.miit.gov.cn/" target="_blank" v-html="this.$store.state.m_user.beiAn.PIC_DESC">

      </a>
    </div>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    // 这里存放数据
    return {
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    goDetil(item) {
      console.log(item);
      this.$router.push({
        path: '/bottomDetil',
        query: {
          name: item.NAME,
        }
      });
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {} // 生命周期 - 挂载之前
};
</script>
<style lang="less">
/* //@import url(); 引入公共css类 */
a {
  color: #fff;
  text-decoration: none;
}
.bottom {
  font-family: Regular;
  width: 100%;
  height: 249px;
  display: flex;
  justify-content: center;
  padding-top: 40px;
  background: #474747;
  .bottomContent {
    width: 1200px;
    color: #fff;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    .neir {
      color: #fff;
      display: flex;
      cursor: pointer;
      .fo:last-child {
        span {
          display: none;
        }
      }
    }
    .nd {
      margin: 15px 0;
    }
    .tu {
      img {
        width: 150px;
        height: 150px;
      }
    }
  }
}
.tio {
  height: 50px;
  line-height: 50px;
  text-align: center;
  width: 100%;
  background: #333;
  color: #fff;
  font-size: 14px;
  .footer-section2 {
    margin: 0;
  }
}
</style>